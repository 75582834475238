import React from 'react';
import flower from './flower_transparent.webp'
import splash from './splash.webp'
import './App.css';
import { useOutletContext } from 'react-router-dom'


function App() {
  const {mobileView} = useOutletContext()
  let marginfix = "";

  if(mobileView){
    marginfix = "25vh";
  }else {
    marginfix = "0vh";
  }



  return (
    <div className="App" style={{padding: mobileView ? "0%" : "0 20vw"}}>
      <header className="App-header">
        <img className='header-logo' src={flower} alt="blomst :)"/>
        <p className='greeting'>
          22. Juni 2024
        </p>
        <img className="splash-image" src={splash} style={{marginRight:marginfix} } alt="Kamilla og OG"/>
      </header>
    </div>
  );
}

export default App;

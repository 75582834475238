import React, {useEffect} from 'react';
import {useOutletContext} from 'react-router-dom'
import './Wishlist.css';
import ReactGA from "react-ga4";

ReactGA.initialize('G-DXMH282P72');


const Wishlist = () => {
  let {mobileView} = useOutletContext()

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/onskeliste", title: "Ønskeliste" });
    
      }, []);
    return <div className='wishlist-container'> 
    <div className='preface'>
      <div className='header'>Ønskeliste</div>
        <div className='toast'> 
          <div className='toastheader'>
            Vi gleder oss til å feire den store dagen med dere!
          </div>

          <div className='wishlist-sections' style={{flexDirection:  mobileView ? "column" : "row"}}>
            <div className='wishlist-section'>
              <div className='name'> Penger til bryllupsreise</div>
              <div className='info'> 
                  <div>Vi ønsker oss mest av alt minnerike opplevelser sammen, og setter derfor stor pris på penger som kan brukes til bryllupsreise.</div>
                  <div>Konto: 4266.50.36825</div>
              </div>
            </div>

            <div className='wishlist-section'>
                <div className='name'> Ønskeliste Tilbords</div>
                <div className='info'> 
                    <div>For de som heller ønsker å gi en fysisk gave så har vi lagt inn ønskeliste hos Tilbords. Den kan nåes <a href='https://www.tilbords.no/onskeliste/138133' target="_blank" rel="noreferrer">her</a>.</div>
                </div>
            </div>

            <div className='wishlist-section'>
                <div className='name'> Ønskeliste CI Pedersen</div>
                <div className='info'> 
                    <div>Vi har også lagt til ønske om servise hos CI Pedersen. Det serviset er kunn tilgjengelig i butikk i Trondheim. Listen kan nåes <a href='https://www.designforevig.no/bryllupslister/483333' target="_blank" rel="noreferrer">her</a>.</div>
                </div>
            </div>
          </div>

        </div>
        <div className='content'>
          Vennligst ikke gi oss blomster, da vi reiser på bryllupsreise rett etter bryllup og ikke vil ha mulighet til å ta dem med oss.
        </div>
      </div>
    </div>;
};

export default Wishlist;
import React, { useRef, useState, useEffect } from 'react';
import {useOutletContext} from 'react-router-dom'
import emailjs from '@emailjs/browser';
import './RSVP.css'
import ReactGA from "react-ga4";
ReactGA.initialize('G-DXMH282P72');

const RSVP = (props) => {
    const form = useRef();
    const [formSent, setFormSent] = useState({
        sent: false,
        error: false,
    })
    let {mobileView} = useOutletContext()

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/rsvp", title: "RSVP" });
    
      }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        ReactGA.send({ hitType: "event", page: "/rsvp", title: "Sent RSVP" });
        

        // Save fullname in cookies
        document.cookie = `fullname=`+ e.target.name.value.replace(/\s+/g, '_') + "_" + e.target.last_name.value.replace(/\s+/g, '-')


        emailjs.sendForm('service_rknia63', 'template_vfy69hi', form.current, 'pRccAjK3E_eSNBkSX')
        .then((result) => {
            setFormSent({sent: true, error: false});
            console.log(result.text);

        }, (error) => {
            setFormSent({sent: true, error: true});
            ReactGA.send({ hitType: "event", page: "/rsvp", title: "Error RSVP" });
            console.log(error.text);
        });
    };

    return (
        <div className='RSVP'>
            {(!formSent.sent && !formSent.error) && (
            <form 
                className="RSVP-form" 
                ref={form} 
                onSubmit={sendEmail} 
                style={{
                    width: mobileView ? '90%' : '66%'
                }}
            >
                <p className='form-title'> Vennligst RSVP før 1. mai 2023</p>
                {/*<p className='form-title'> Vi har rabattkode for Oppdal Turisthotel som kan brukes ved bestilling av rom</p>*/}

                <div className='form-section' >
                    <p>Navn <span className="required">*</span></p>
                    <div className='names' style={{flexDirection: mobileView ? 'column' : 'row'}}>
                        <div className='form-field'>
                            <label>Fornavn</label>
                            <input type="text" name="name" style={{width:mobileView ? "100%" : "95%"}}/>
                        </div>
                        <div className='form-field'>
                            <label>Etternavn</label>
                            <input type="text" name="last_name"/>
                        </div>
                    </div>
                </div>
                <div className='form-section'>
                    <div className='form-field'>
                        <p>Epost<span className="required">*</span></p>
                        <input type="email" name="user_email" />
                    </div>
                </div>
                <div className='form-section'>
                    <div className='form-field'>
                        <p>Har du/dere mulighet til å komme i bryllupet?<span className="required">*</span></p>
                        <div className='form-field2'>
                            <input type="radio" name="saturday" value="Ja" id="saturday-yes"/>
                            <label className='option'>Ja</label>
                        </div>
                        <div className='form-field2'>
                            <input type="radio" name="saturday" value="Nei" id="saturday-no"/>
                            <label className='option'>Nei</label>
                        </div>
                    </div>
                </div>
                <div className='form-section'>
                    <div className='form-field'>
                        <p>Har du/dere tenkt til å overnatte på Oppdal Turisthotel?<span className="required">*</span></p>
                        <div className='form-field2'>
                            <input type="radio" name="friday" value="Ja" id="friday-yes"/>
                            <label className='option'>Ja</label>
                        </div>
                        <div className='form-field2'>
                            <input type="radio" name="friday" value="Nei" id="friday-no"/>
                            <label className='option'>Nei</label>
                        </div>
                    </div>
                </div>

                <div className='form-section'>
                    <p>Navn på følge<span className="required">*</span></p>
                    <div className='names' style={{flexDirection: mobileView ? 'column' : 'row'}}>
                        <div className='form-field'>
                            <label>Fornavn</label>
                            <input type="text" name="name_plus_one" style={{width:mobileView ? "100%" : "95%"}}/>
                        </div>
                        <div className='form-field'>
                            <label>Etternavn</label>
                            <input type="text" name="last_name_plus_one"/>
                        </div>
                    </div>
                </div>
                <div className='form-section'>
                    <div className='form-field'>
                    <p>Annet</p>
                    <textarea name="message" placeholder='Skriv om det er noe annet vi burde vite om.'/>
                    </div>
                </div>
                <input className="submit-button" type="submit" value="Send" />
            <div>
                <p className='form-title'>Vi gleder oss til å høre fra deg!</p>
            </div>
            </form>
            )}
            {(formSent.sent && !formSent.error) && (
                <div className='form-sent'><h2>Tusen takk for svar!</h2></div>
            )}
            {(formSent.sent && formSent.error) && (
                <div className='form-sent-error'>
                    <h2>Noe gikk feil!</h2>
                    <p>Vennligst kontakt Kamilla på <code>45419101</code> / <code>kamilla_lonset@hotmail.com</code> eller Odd Gunnar på <code>93672591</code>/ <code>odd.gunnar.aspaas@gmail.com</code></p>    
                </div>
            )}
                
        </div>
    );
};

export default RSVP;
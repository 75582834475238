import React from 'react'
import './Navbar.css';
import NavMenuHandler from './NavMenuHandler';
import MenuItem from './MenuItem/MenuItem';
import { NavLink } from 'react-router-dom';


const NavBar = (props) => {
  return (
    <div className="navbar" style={{
      flexDirection: props.isMobile ? "row" : "column",
      paddingBottom: props.isMobile ? "0" : "5vw"
    }}>
      <NavLink 
        to='/'
        className="home"
      >
        <p className='home-link'>Kamilla & Odd Gunnar</p>
      </NavLink>
      <NavMenuHandler isMobile={props.isMobile}>
        <MenuItem title={'INFORMASJON'} />
        <MenuItem title={'RSVP'} />
        <MenuItem title={'MENY'} />
        <MenuItem title={'ØNSKELISTE'} />
        <MenuItem title={'BILDER'} />
      </NavMenuHandler>
    </div>
  );
}

export default NavBar;
